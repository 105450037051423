import { useStoreActions, useStoreState } from 'easy-peasy';
import LoginPage from './LoginPage';
import Page from './Page';
import { Platform } from 'react-native';
import { useEffect } from 'react';

function ProtectedPage({ children, route, ...props }) {
  useEffect(() => {
    if (route?.params && route?.params?.token) {
      localStorage.setItem('token', route?.params?.token);
    }
  }, [route]);

  return <Page {...props}>{children}</Page>;
}

export default ProtectedPage;
