import React from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import Home from '../pages/Home';
import DrawerC from '../containers/Drawer';
import Image from '../presentational/Image';
import { createStackNavigator } from '@react-navigation/stack';

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

export default function AppDrawerNavigator() {
  const config = {
    screens: {
      Home: 'home/:token?',
    },
  };

  return (
    <NavigationContainer
      linking={{
        prefixes: ['https://cfbsc2a.cfbs-us.com', 'http://localhost:19006'],
        config: config,
      }}
    >
      <Stack.Navigator
        screenOptions={{
          headerShown: true,
          headerRight: () => <DrawerC.Header />,
        }}
      >
        <Stack.Screen
          name='Home'
          component={Home}
          options={{
            title: 'CFBS Admin',
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
